import '../scss/third-part.scss';

import { Link } from 'gatsby';
import React, { ForwardedRef } from 'react';

import { SiteSectionProps } from '../models/section.model';
import MailchimpFormContainer from './mailchimpform/mailchimpFormContainer';

const ThirdPart = (props: SiteSectionProps, ref: ForwardedRef<HTMLInputElement>) => {
  return (
    <div ref={ref} className="third-background">
      <Link to="/">
        <img className="logo-corner-third" src={'/third-part/logo-corner.svg'} />
      </Link>
      <div className="title-third">
        <img className="paper-plane" src={'/third-part/paper-plane.png'} />
        <h3 className="get">get</h3>
        <h1 className="in-touch">in touch</h1>
      </div>
      <MailchimpFormContainer></MailchimpFormContainer>
      <Link to="/">
        <img className="logo-third" src={'/third-part/logo.svg'} />
      </Link>
      <div className="footer">
        <p className="footer-row">
          Coineqt Group Limited has registration number C-86650 with office address at 30/1, Kenilworth Court, Sir Augustus
          Bartolo Street, Ta' Xbiex XBX1093
        </p>
        <p className="footer-row">Copyright © 2022, Coineqt Group Limited. All rights reserved.</p>
      </div>
    </div>
  );
};

export default React.forwardRef(ThirdPart);

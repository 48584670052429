export const breakpoints = (() => {
  enum options {
    xs = '480px',
    sm = '640px',
    md = '768px',
    lg = '1024px',
    xl = '1280px',
  }

  return {
    down: (type: keyof typeof options) => {
      return `@media (max-width:${options[type]})`;
    },
    up: (type: keyof typeof options) => {
      return `@media (min-width:${options[type]})`;
    },
  };
})();

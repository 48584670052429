class TakeshapeService {
  private url = process.env.GATSBY_TAKESHAPE_URL || '';
  private API_KEY = process.env.GATSBY_TAKESHAPE_APIKEY || '';

  queryData(query: string, variables = {}) {
    return fetch(this.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.API_KEY}`,
      },
      body: JSON.stringify({ query, variables }),
    }).then((res) => res.json());
  }
}

export default new TakeshapeService();

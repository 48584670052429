import './column.scss';

import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';

import CommonButton from '../../buttons/CommonButton/CommonButton';
import { ColumnProps } from './column.model';

const Column = (props: ColumnProps) => {
  const column = props.column;

  let button;

  if (!!column.websiteUrl) {
    button = (
      <CommonButton className="text-center" type="link" href={column.websiteUrl} target="_blank">
        <span>{column.buttonLabel}</span>
      </CommonButton>
    );
  } else {
    button = (
      <OutboundLink href={column.comingSoonUrl} target="_blank">
        <img className="coming-soon" src={'/carousel/coming-soon.svg'} />
      </OutboundLink>
    );
  }

  return (
    <div className="column">
      <OutboundLink href={column.websiteUrl || column.comingSoonUrl} target="_blank">
        <img
          className={`base-img ${column.imgClass}`}
          src={`https://images.takeshape.io/${column.image?.path}`}
          alt={column.image?.caption}
        />
      </OutboundLink>
      <div className="logo-row">
        <div className="logo-div">
          <img className="column-logo" src={`https://images.takeshape.io/${column.logo?.path}`} alt={column.logo?.caption} />
        </div>
        {button}
      </div>
      <div className="description">{column.description}</div>
    </div>
  );
};

export default Column;

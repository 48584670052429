import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import ContactForm from '../contactForm/contactForm';

function MailchimpFormContainer() {
  const url = `https://ancient.us14.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  return (
    <>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <ContactForm status={status} formMessage={message} onValidated={(formData) => subscribe(formData)}></ContactForm>
        )}
      ></MailchimpSubscribe>
    </>
  );
}

export default MailchimpFormContainer;

import '../scss/second-part.scss';

import { Link } from 'gatsby';
import React, { ForwardedRef } from 'react';

import { SiteSectionProps } from '../models/section.model';
import DownButton from './buttons/DownButton/DownButton';
import Carousel from './carousel/Carousel';

const SecondPart = (props: SiteSectionProps, ref: ForwardedRef<HTMLInputElement>) => {
  return (
    <div ref={ref} className="second-background">
      <Link to="/">
        <img className="logo-second" src={'/second-part/logo.svg'} />
      </Link>
      <div className="title-second">
        <img className="rod" src={'/second-part/rod.png'} />
        <h3 className="our">our</h3>
        <h1 className="magic">magic</h1>
      </div>
      <Carousel></Carousel>
      <DownButton className="get-in-touch" arrowDown={'/second-part/arrow-down.png'} onClick={props.onClick}>
        <span>get in touch</span>
      </DownButton>
    </div>
  );
};

export default React.forwardRef(SecondPart);

import React from 'react';

function CareerIcon({ fill }: { fill?: string }) {
  return (
    <svg width="39" height="30" viewBox="0 0 39 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.7829 19.0567C23.738 18.4597 23.7406 17.9315 23.6508 17.4164C23.3972 15.9372 22.309 14.944 20.8113 14.8463C19.9555 14.7908 19.0971 14.8093 18.2386 14.8225C16.2311 14.849 14.9659 16.1089 14.9078 18.1296C14.8999 18.4307 14.9078 18.7318 14.9078 19.0778C13.3362 18.8956 11.857 18.7001 10.3725 18.5601C7.94243 18.3303 5.50707 18.1375 3.07434 17.9315C1.49214 17.7967 0.467283 16.8009 0.377475 15.2214C0.232199 12.5853 0.108053 9.94916 0.00239724 7.31041C-0.0557135 5.81009 0.948017 4.6426 2.42984 4.42336C2.70983 4.3811 3.00038 4.37582 3.28565 4.37582C14.0018 4.37582 24.7179 4.37582 35.4341 4.37053C36.6359 4.37053 37.6793 4.69542 38.2657 5.81273C38.5007 6.25913 38.6434 6.81647 38.6302 7.31569C38.5483 9.95444 38.4162 12.5906 38.2762 15.2293C38.197 16.7191 37.1906 17.7783 35.7669 17.9183C31.892 18.3013 28.0197 18.679 24.1448 19.0567C24.0365 19.0673 23.9282 19.0567 23.7802 19.0567H23.7829Z" fill={fill} />
      <path d="M36.9344 19.1227C36.945 19.3605 36.9635 19.5559 36.9635 19.7487C36.9635 22.3664 36.9687 24.984 36.9635 27.6016C36.9582 29.2261 36.1895 30 34.5888 30C24.4274 30 14.2659 30 4.10449 30C2.47739 30 1.68497 29.2102 1.68233 27.5699C1.67704 24.778 1.68233 21.986 1.68233 19.1122C1.94911 19.1122 2.18155 19.0937 2.41135 19.1148C6.50551 19.5031 10.597 19.8914 14.6886 20.3008C14.8761 20.3193 15.1165 20.4936 15.2089 20.6627C15.8851 21.8751 16.8968 22.4615 18.2835 22.4324C19.031 22.4166 19.7785 22.4271 20.526 22.4271C21.8362 22.4271 22.7739 21.8275 23.4289 20.7049C23.5425 20.5095 23.8331 20.3193 24.0576 20.2955C28.0831 19.8887 32.1112 19.5057 36.1367 19.1254C36.3744 19.1016 36.6174 19.1227 36.9344 19.1227Z" fill={fill} />
      <path d="M19.3136 21.1777C18.8514 21.1777 18.3892 21.1962 17.9296 21.1725C16.9813 21.1223 16.284 20.4408 16.2259 19.4925C16.1915 18.922 16.1915 18.3488 16.2259 17.7783C16.2787 16.8511 16.9337 16.1538 17.8609 16.1063C18.825 16.0561 19.797 16.0587 20.7611 16.101C21.7173 16.1432 22.3803 16.8406 22.4411 17.8073C22.4728 18.333 22.4728 18.8639 22.4463 19.3895C22.3935 20.4936 21.6883 21.154 20.5683 21.1777C20.151 21.1857 19.7336 21.1777 19.3163 21.1777H19.3136Z" fill={fill} />
      <path d="M19.3585 0.0122287C20.6555 0.0122287 21.9524 -0.0273923 23.2467 0.0360012C23.664 0.0571324 24.1104 0.27901 24.4644 0.52466C24.9477 0.860117 25.3704 1.28802 25.7877 1.70801C26.2684 2.18874 26.2869 2.73023 25.8828 3.17398C25.4866 3.60717 24.8236 3.71282 24.4221 3.22945C23.4606 2.06988 22.235 1.98271 20.88 2.04875C19.3902 2.11742 17.8926 2.08837 16.4002 2.05139C15.8059 2.03818 15.3383 2.17553 14.9421 2.62721C14.7123 2.88871 14.4429 3.12643 14.155 3.31926C13.6769 3.63887 13.183 3.61245 12.7868 3.17662C12.3879 2.74079 12.3773 2.22836 12.7841 1.81102C13.3335 1.24576 13.9199 0.71484 14.5406 0.231465C14.7546 0.0650566 15.1138 0.0280771 15.407 0.0227943C16.7251 0.0016631 18.0431 0.0148701 19.3612 0.0148701L19.3585 0.0122287Z" fill={fill} />
    </svg>

  );
}

export default CareerIcon;

import React from 'react';
import { DownButtonProps } from './down-button.model';
import './down-button.scss';

const DownButton = (props: DownButtonProps) => {
  const className = props.className || '';
  return (
    <a className={`down-button text-center sticky w-full bottom-0 mb-4 flex flex-col ${className}`} onClick={props.onClick}>
      <span className="label">{props.children}</span>
      <span className="arrow-down mt-2 mb-0 mx-auto">
        <img src={props.arrowDown} />
      </span>
    </a>
  );
};

export default DownButton;

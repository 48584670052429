import './carousel.scss';
import 'swiper/css';
import 'swiper/css/navigation';

import { useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { breakpoints } from '../../constants/breakpoints';
import takeshapeService from '../../shared/services/takeshapeService';
import Column from './column/Column';
import { ColumnPage } from './column/column.model';

const Carousel = () => {
  const smdown = useMediaQuery(breakpoints.down('sm'));
  const [slides, setSlides] = useState<{ items: ColumnPage[]; length: number }>({ items: [], length: 0 });

  function getData() {
    const query = `query($sort: [TSSearchSortInput]) {
      getSitesCarouselList(sort: $sort) {
        items {
          _id
          buttonLabel
          description
          imgClass
          comingSoonUrl
          image {
            caption
            title
            path
          }
          logo {
            caption
            title
            path
          }
          name
          websiteUrl
        }
        total
      }
    }`;

    const queryVariables = {
      "sort": {
        "field": "name",
        "order": "asc"
      }
    };

    const fetchData = takeshapeService.queryData(query, queryVariables);
    fetchData.then(({ data }) => {
      const { getSitesCarouselList } = data;
      setSlides(getSitesCarouselList);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  return slides.items.length ? (
    <div className="carousel">
      <Swiper modules={[Navigation]} spaceBetween={50} navigation slidesPerView={smdown ? 1 : 2}>
        {slides.items.map((slide) => (
          <SwiperSlide key={slide.name}>
            <Column column={slide}></Column>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : null;
};

export default Carousel;

import '../../scss/contact-form.scss';

import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { validateEmail, validateRequired } from '../../utils/validationUtils';
import CommonButton from '../buttons/CommonButton/CommonButton';
import { ContactFormProps } from './contactform.model';


function ContactForm({ status, formMessage, onValidated }: ContactFormProps) {
  const swal = withReactContent(Swal);
  const [messageSentText, setMessage] = useState<string | null>(null);
  const [textAreaCount, setTextAreaCount] = useState(0);
  const [nameCount, setNameCount] = useState(0);
  const nameMaxLength = 50;
  const messageMaxLength = 140;

  useEffect(() => {
    if (status !== 'sending') {
      switch (status) {
        case 'error':
          swal.fire({
            title: <p>Something happened!</p>,
            html: <span>{formMessage}</span>,
            icon: 'error',
          });
          break;
        case 'success':
          setMessage('Thank you for your message. We’ll contact you shortly.');
          break;
      }
    }
  }, [status]);

  function resetMessage() {
    setMessage(null);
  }

  return (
    <>
      <Formik
        initialValues={{
          name: '',
          email: '',
          message: '',
        }}
        onSubmit={({ name, email, message }, { resetForm }) => {
          name && email && message && onValidated!({ NAME: name, EMAIL: email, MESSAGE: message });
          setTextAreaCount(0);
          setNameCount(0);
          resetForm({ errors: {}, touched: {} });
        }}
      >
        {({ values, handleChange,handleBlur, errors, touched }) => (
          <Form className="get-in-touch-form">
            <Field
              id="name"
              name="name"
              type="text"
              className="form-input mb-4"
              placeholder="Name*"
              maxLength={nameMaxLength}
              onBlur={handleBlur}
              onChange={(e: any) => { if (e.target.value.length > nameMaxLength) { e.target.value.substr(0, nameMaxLength) } else { setNameCount(e.target.value.length); handleChange(e); } }}
              value={values.name}
              validate={validateRequired}
            ></Field>
            <div className="input-messages">
              <div className="error-msg"> {(errors.name && touched.name) && errors.name}</div>
              <span className="character-count">{nameCount}/{nameMaxLength}</span>
            </div>

            <Field
              id="email"
              name="email"
              type="email"
              className="form-input mb-4"
              placeholder="Email address*"
              onChange={handleChange}
              onBlur={handleBlur}
              validate={validateEmail}
              value={values.email}
            ></Field>
            <div className="error-msg"> {(errors.email && touched.email) && errors.email}</div>

            <Field
              id="message"
              name="message"
              type="text"
              className="form-input textarea mb-4"
              placeholder="Message*"
              component="textarea"
              rows="5"
              maxLength={messageMaxLength}
              onBlur={handleBlur}
              onChange={(e: any) => { if (e.target.value.length > messageMaxLength) { e.target.value.substr(0, messageMaxLength) } else { setTextAreaCount(e.target.value.length); handleChange(e); } }}
              validate={validateRequired}
              value={values.message}
            ></Field>
            <div className="input-messages">
              <div className="error-msg"> {(errors.message && touched.message) && errors.message}</div>
              <span className="character-count">{textAreaCount}/{messageMaxLength}</span>
            </div>

            <div className="button-div">
              <CommonButton className={`send-message apply-newsletter text-center ${messageSentText ? 'hidden' : 'd-block'}`} type="button" >
                <>
                  <span>Send message</span>
                </>
              </CommonButton>
            </div>
          </Form>
        )}
      </Formik>
      <CommonButton className={`send-message apply-newsletter text-center mb-12 ${messageSentText ? 'success d-block' : 'hidden'}`} type="button" onClick={() => {
        resetMessage();
      }}>
        <>
          {messageSentText && (<><span>{messageSentText}</span> <br /><a>SEND ANOTHER MESSAGE</a></>)}
        </>
      </CommonButton>
    </>
  );
}

export default ContactForm;

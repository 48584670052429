export function validateEmail(value: string) {
  let error;
  if (!value) {
    error = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
}

export function validateUsername(value: string) {
  let error;
  if (value === 'admin') {
    error = 'Nice try!';
  }
  return error;
}

export function validateRequired(value: string) {
  let error;
  if (!value || value.trim().length === 0) {
    error = 'This field is required';
  }

  return error;
}
import './common-button.scss';

import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';

import { CommonButtonProps } from './common-button.model';

const CommonButton = (props: CommonButtonProps) => {
  const className = props.className || '';
  const attributes = {
    ...(props.href && { href: props.href }),
    ...(props.target && { target: props.target }),
  };
  return props.type === 'link' ? (
    <OutboundLink className={`common-button ${className}`} {...attributes}>
      {props.children}
    </OutboundLink>
  ) : (
    <button onClick={props.onClick} className={`common-button ${className}`} {...attributes}>
      {props.children}
    </button>
  );
};

export default CommonButton;

import '../scss/first-part.scss';

import React, { useEffect, useState } from 'react';

import { SiteSectionProps } from '../models/section.model';
import takeshapeService from '../shared/services/takeshapeService';
import DownButton from './buttons/DownButton/DownButton';

const FirstPart = (props: SiteSectionProps) => {
  const [firstSectionText, setFirstSectionText] = useState({ _id: '', title: '', text: '' });

  function getData() {
    const query = `{
      getFirstSectionText {
        _id
        text
        title
      }
    }
    `;

    const fetchData = takeshapeService.queryData(query);
    fetchData.then(({ data }) => {
      const { getFirstSectionText } = data;
      setFirstSectionText(getFirstSectionText);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="base-container">
      <div className="first-background">
        <div className="content">
          <img className="logo-first" src={'/third-part/logo.svg'} />
          <div className="text">
            <h1 className="title-first">{firstSectionText.title}</h1>
            <p className="first-text">{firstSectionText.text}</p>
          </div>
        </div>

        <DownButton className="our-magic" arrowDown={'/first-part/arrow-down.png'} onClick={props.onClick}>
          <span>our magic</span>
        </DownButton>
      </div>
    </div>
  );
};

export default FirstPart;
